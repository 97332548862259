export default {
    welcome: "Velkomin á 1819",
    enable_offer: "Virkja tilboð",
    website: "Vefsíða",
    opening_hours: "Opnunartímar",
    offer_enabled_for: "Tilboð virkt í",
    sec: "sek.",
    close: "Loka",
    about: "1819",
    most_popular: "Vinsælustu tilboðunum",
    newest_first: "Nýjustu tilboðum fyrst",
    oldest_first: "Elstu tilboðum fyrst",
    sort: "Raða eftir",
    active_now: "Virk núna",
    load_more: "Hlaða inn fleiri tilboðum...",
    use_this_code: "Notaðu þennan kóða til að virkja tilboðið",
    all_offers: "Öll tilboð",
    top_offers: "Helstu tilboð",
    home: "Heim",
    show_promocode: "Sýna afsláttarkóða",

    ad: "AUGLÝSING",

    monday: "Mánudagur",
    tuesday: "Þriðjudagur",
    wednesday: "Miðvikudagur",
    thursday: "Fimmtudagur",
    friday: "Föstudagur",
    saturday: "Laugardagur",
    sunday: "Sunnudagur",

    monday_medium: "mán.",
    tuesday_medium: "þri.",
    wednesday_medium: "mið.",
    thursday_medium: "fim.",
    friday_medium: "fös.",
    saturday_medium: "lau.",
    sunday_medium: "sun.",

    monday_short: "M",
    tuesday_short: "Þ",
    wednesday_short: "M",
    thursday_short: "F",
    friday_short: "F",
    saturday_short: "L",
    sunday_short: "S",

    offer_not_available_today: "Tilboð ekki virkt í dag",
    offer_not_available: "Tilboð ekki virkt",
    offer_was_available: "Tilboð var virkt",
    offer_today: "í dag",
    offer_is_available: "Tilboð er virkt",
    offer_is_available_all_day: "Tilboð er virkt í allan dag",
    offer_will_be_available: "Tilboð verður virkt",

    companies: "Fyrirtæki",
    individuals: "Einstaklingar",

    load_more_companies: "Sækja fleiri fyrirtæki",
    load_more_individuals: "Sækja fleiri einstaklinga",

    special_offer: "Sérkjör",
    two_for_one: "2 fyrir 1",
    introduction: "Kynning",

    login: "Innskráning",

    terms_conditions: "Notendaskilmálar",
    privacy_policy: "Persónuverndarskilmálar",

    searchPlaceholder: "Hverju ertu að leita að?",
    searchButton: "Leita",

    loginContainerTitle: "Innskráning á innri vef 1819.is",
    loginUsernameTitle: "Netfang",
    loginPasswordTitle: "Lykilorð",
    loginButtonTitle: "Innskrá",

    //==========================================================
    map: "Kortið",
    services_1819: "Þjónusta 1819",
    about_1819: "Um 1819",
    open: "Opið",
    closed: "Lokað",

    front_page: "Forsíða",
    search_results: "Leitarniðurstöður",
    search_results_for: "Leitarniðurstöður fyrir",

    nothing_found_with: "Ekkert fannst með leitarorðinu",
    nothing_found_using: "notast við",

    no_results_for: "Engar niðurstöður fundust fyrir",
    error_when_search: "Óvænt villa kom upp við leit",
    not_finding_what_you_are_looking_for: "Finnur þú ekki það sem þú leitar að?",
    we_can_help: "Við getum aðstoðað þig í síma 1819",
    call_1819: "Hringja í 1819",
    have_a_suggestion: "Hefur þú ábendingu fyrir okkur?",
    cannot_connect: "Næ ekki sambandi við vefþjón 1819. Er tækið þitt nettengt?",
    think_this_is_error: "Telur þú að þetta sé villa?",
    here_you_can_send_us: "Hér getur þú sent okkur ábendingu",
    send_us_a_message: "Senda okkur ábendingu",
    you_know_where_to_look: "...þú veist hvar þú átt að leita.",

    //==========================================================
    // INFO DETAILS
    info_details_opening_hours: "Opnunartímar",
    info_details_phone_number_singular: "Símanúmer",
    info_details_phone_number_plural: "Símanúmer",
    info_details_website_singular: "Vefsíða",
    info_details_website_plural: "Vefsíður",
    info_details_email_singular: "Netfang",
    info_details_email_plural: "Netföng",
    info_details_kennitala: "Kennitala",
    info_details_fyrirtaekjaskra: "Fyrirtækjaskrá",
    info_details_keldan: "Keldan fjárhagsupplýsingar",
    info_details_social_media: "Samfélagsmiðlar",
    info_details_map: "Kort",
    info_details_tags: "Merkingar",
    info_details_brands: "Vörumerki",
    info_details_services: "Þjónustur",
    info_details_other_listings: "Aðrar skráningar",
    info_details_employees: "Starfsfólk",
    info_details_navigate: "Vegvísun",
    info_details_more_info: "Nánari upplýsingar",

    map_search_on_map: "Leita á korti",

    //==========================================================
    // OPENING HOURS
    opening_hours_open_until: "Opið til",
    opening_hours_open_until_midnight: "Opið til miðnættis",
    opening_hours_opens_at: "Opnar kl.",
    opening_hours_closed: "Lokað",
    opening_hours_always_open: "Alltaf opið",

    //==========================================================
    // FOOTER

    footer_opening_hours: "Opnunartímar",
    footer_thjonustuver: "Þjónustuver",
    footer_skrifstofa: "Skrifstofa",
    footer_weekdays: "Virkir dagar",
    footer_weekends: "Helgar",

    //==========================================================
    // MITT
    authenticating: "Vinn úr auðkenningu...",
    shortcuts: "Flýtileiðir",
    my_account: "Minn aðgangur",
    my_companies: "Mín fyrirtæki",
    my_data_at_1819: "Mínar upplýsingar hjá 1819",
    full_name: "Fullt nafn",
    kennitala: "Kennitala",
    company_kennitala: "Kennitala fyrirtækis",
    registration_date: "Dagsetning skráningar",
    extension_3cx: "Auðkenni símkerfis",
    roles: "Hlutverk",
    sms_gateway: "SMS gátt",
    service_phone: "Þjónustusími",
    thjodskra: "Þjóðskrá",
    scripts: "Handrit",
    my_registrations: "Mínar skráningar",
    my_registration: "Mín skráning",
    answering_stats: "Tölfræði svörunar",
    register_company: "Nýskrá fyrirtæki á 1819.is",
    my_data_description:
        "Hér getur þú náð í þau gögn sem við höfum um þig. Þú getur líka eytt þér út úr kerfinu hjá okkur.",
    download_my_data: "Sækja mín gögn",
    delete_user: "Eyða mér",

    register_company_modal_title: "Skrá nýtt fyrirtæki á 1819.is",
    register_company_modal_info:
        "Settu inn helstu upplýsingar um fyrirtækið. Þessar upplýsingar munu svo birtast á 1819.is þegar við höfum farið yfir skráninguna. Eftir að við höfum samþykkt skráninguna er hægt að bæta við fleiri upplýsingum.",

    register_company_modal_info_step: "Upplýsingar",
    register_company_modal_access_step: "Aðgangur",
    register_company_modal_communication_step: "Samskipti",
    register_company_modal_done_step: "Lokið",

    company_address: "Company Address",

    // Edit Registration
    registration: "Skráning",
    information: "Upplýsingar",
    company_info: "Fyrirtækjasíða",
    branches: "Viðbótarskráningar",
    create: "Stofna",
    save: "Vista",
    get_info_from_thjodskra: "Sækja upplýsingar í Þjóðskrá",
    fetch: "Sækja",
    edit: "Breyta",
    basic_info: "Grunnupplýsingar",
    job_title: "Starfsheiti",
    activity: "Starfsemi",
    show_keldan: "Sýna Kelduna",
    show_company_registry: "Sýna Fyrirtækjaskrá",
    address: "Heimilisfang",
    street_name: "Götuheiti",
    house_number: "Húsnúmer",
    postal_code: "Póstnúmer",
    select_postal_code: "Veldu póstnúmer",
    show_address: "Sýna heimilisfang",
    allow_updates: "Birta samkvæmt Þjóðskrá",
    get_location: "Sækja staðsetningu",
    phone_numbers: "Símanúmer",
    banned: "Bannmerkt",
    visible: "Sýnilegt",
    add_email: "Bæta netfangi við",
    add_website: "Bæta vefsíðu við",
    add_phone_number: "Bæta símanúmeri við",
    add_social_media: "Bæta samfélagsmiðli við",
    no_phone: "Ekkert símanúmer",
    no_phone_details: "Smelltu til að bæta símanúmeri við.",
    no_email: "Ekkert netfang",
    no_email_details: "Smelltu til að bæta netfangi við.",
    no_website: "Engin vefsíða",
    no_website_details: "Smelltu til að bæta vefsíðu við.",
    no_social_media: "Engir samfélagsmiðlar",
    no_social_media_details: "Smelltu til þess að bæta samfélagsmiðli við.",
    actions: "Aðgerðir",
    preview: "Forskoðun",
    preview_description_save: "Svona lítur skráningin þín út á 1819.is!",
    preview_description_create: "Svona mun skráningin þín líta út á 1819.is!",

    logo: "Lógó",
    header: "Mynd í haus",
    upload: "Setja inn",
    slugs: "Stuttar slóðir",
    background_color: "Bakgrunnslitur",
    keywords_is: "Leitarorð IS",
    keywords_en: "Leitarorð EN",
    services_is: "Þjónustur IS",
    services_en: "Þjónustur EN",
    brands: "Vörumerki",
    main_hours: "Aðal",
    other_hours: "Auka",
    new_slug: "Ný stutt slóð",
    no_slugs: "Engin stutt slóð",
    new_opening_hours: "Nýr opnunartími",
    special_hours: "Sérstakir opnunartímar",
    no_special_hours: "Engir sérstakir opnunartímar",
    click_enter_to_add: "Ýttu á enter til að bæta við",
    opens: "Opnar",
    closes: "Lokar",

    sub_registrations: "Undirskráningar",
    staff: "Starfsfólk",
    no_branches: "Engar undirskráningar",
    no_branches_subtitle: "Þú getur bætt nýrri undirskráningu við hér að ofan.",
    new_branch: "Ný undirskráning",

    no_staff: "Ekkert starfsfólk skráð",
    no_staff_subtitle: "Þú getur bætt starfsfólki við hér að ofan.",
    new_staff: "Nýr starfsmaður",

    logout: "Útskráning",

    settings: "Stillingar",
    settings_for: "Stillingar fyrir",
    users_for: "Notendur",
};
