import * as React from "react";

function PersonIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
            <path
                d="M237.5 1.4c-24 4-41.6 12.2-56.7 26.4-24.9 23.5-35.2 53.6-35.1 103.2.1 37.2 6 65.1 18.9 89.9 16.9 32.7 43.3 50.5 80 54.2 20.1 2.1 40.1-1.1 57.2-9.1 19.9-9.3 36-25.7 47.1-47.9 4.4-8.6 10.6-27.5 13-39.6 5.3-26.6 5.6-67.4.6-92.3-7.8-38.8-31.7-67.3-66.5-79.4-16-5.6-42.6-8-58.5-5.4zm42 30.5c32.9 8.7 51.6 32.1 56.6 70.6 1.9 14.9 1.7 47.6-.5 61-7.4 46.1-26.1 72.4-57.7 81.1-4.8 1.4-10.3 1.8-21.9 1.9-17.4 0-25.9-1.8-37.5-7.8-8.5-4.4-21.8-18-27.4-28.1-12.1-21.4-18-52.5-16.8-88.1 1.2-35.5 8-55.7 24.1-72 10.4-10.5 22.9-16.9 39.1-20.1 10.3-1.9 31.8-1.2 42 1.5z"
                fill="currentColor"
            />
            <path
                d="M177 276.8c-1.9 1.1-7.8 4.5-13 7.7-20.6 12.6-46.4 24-71 31.5-15 4.5-23.2 9.1-31.6 17.6-16 16.1-22.6 37.9-22.7 74.9-.1 29.4 2.6 45.2 8.8 51 4.6 4.4 26.2 15.2 45.5 22.8 87.2 34.5 196.8 39.1 290.2 12.3 33.9-9.7 75.5-28 82.8-36.3 7.2-8.1 10-49.4 5.5-78.8-3.9-25-14-43-30.1-53.5-7.1-4.7-13.3-7.2-30.4-12.5-20.6-6.5-43-16.8-64-29.5-9.3-5.7-14.8-8.4-17.5-8.7-10.6-1.1-18.6 10.3-14 20.1 4.5 9.5 52.3 34.4 86.5 45.1 8 2.5 16.9 5.7 19.7 7.1 10.1 5 17.5 16.2 21 31.5 1.4 5.9 1.7 12.3 1.7 31.4-.1 13.2-.5 25.3-1 26.9-.7 2.2-2.6 3.7-7.4 6.2-39.1 20.2-92.4 34.3-145.4 38.5-21.9 1.7-69.6.6-89.6-2.1-46.8-6.2-89-18.4-122.8-35.4l-9.1-4.6-.8-4.7c-.4-2.7-.8-14.7-.7-26.8.1-24.5 1.2-32.1 6.3-43.1 5.6-12 13.1-17.9 28.1-22.3 16.5-4.8 34.5-12 53.5-21.6 25-12.5 37.6-20.5 40.6-25.8 6.7-12.1-6.8-25.4-19.1-18.9z"
                fill="currentColor"
            />
        </svg>
    );
}

export default PersonIcon;
