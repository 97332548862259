import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { createWrapper } from "next-redux-wrapper";

const composedMiddleware = applyMiddleware(thunk);

const storeEnhancers = composeWithDevTools({})(composedMiddleware);

let store;
export const makeStore = (initialState) => {
    store = createStore(rootReducer, initialState, storeEnhancers);
    return store;
};
export { store };
export const wrapper = createWrapper(makeStore, { debug: true });
