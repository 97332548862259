import * as React from "react";

function IcelandFlag(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
            <path
                d="M400 0H112C50.144 0 0 50.144 0 112v288c0 61.856 50.144 112 112 112h288c61.856 0 112-50.144 112-112V112C512 50.144 461.856 0 400 0z"
                fill="#41479b"
            />
            <path
                fill="#f5f5f5"
                d="M512 211.862H229.517V0h-88.276v211.862H0v88.276h141.241V512h88.276V300.138H512z"
            />
            <path
                fill="#ff4b55"
                d="M512 229.517H211.862V0h-52.965v229.517H0v52.966h158.897V512h52.965V282.483H512z"
            />
        </svg>
    );
}

export default IcelandFlag;
