import React from "react";
import { THEME_COLOR } from "@utils/constants";

export function Triangle({ color = THEME_COLOR, size = "100%", ...props }) {
    return (
        <div className="triangle-wrapper" style={{ zIndex: 4, position: "relative" }} {...props}>
            <svg
                height={size}
                width={size}
                style={{ zIndex: 4, position: "absolute" }}
                viewBox="0 0 689 689"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="m37.7016269 253.469101 215.7674741-215.7674741c50.268836-50.2688359 131.770722-50.2688359 182.039558 0l215.789714 215.7897141c50.268836 50.268836 50.268836 131.770722 0 182.039558l-215.767474 215.767474c-50.268836 50.268836-131.770722 50.268836-182.039558 0l-215.7897141-215.789714c-50.2688359-50.268836-50.2688359-131.770722 0-182.039558z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
}
