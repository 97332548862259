import * as Routes from "./routes";
import ability from "@utils/ability";

export default function MenuBuilder(user) {
    if (!user) {
        return [];
    }
    let menu = [{ ...Routes.ADGANGUR }, { ...Routes.UTSKRA }];

    const callCenter = [];

    if (ability(user).can("answer", "thjonustuver")) {
        callCenter.push(Routes.THJONUSTUSIMI);
    }
    if (ability(user).can("read", "own-call-campaigns")) {
        callCenter.push(Routes.UTHRINGIVER);
    }

    if (ability(user).can("read", "answering-companies")) {
        callCenter.push(Routes.SIMSVORUN_FYRIRTAEKJA);
    }
    if (ability(user).can("read", "thjonustuver-statistics")) {
        callCenter.push(Routes.SIMTOL);
    }
    if (ability(user).can("read", "thjonustuver-comments")) {
        callCenter.push(Routes.ATHUGASEMDIR_THJONUSTUVERS);
    }

    if (ability(user).can("read", "static-info")) {
        callCenter.push(Routes.STARFSMANNAHANDBOK);
        callCenter.push(Routes.HAGNYTAR_UPPLYSINGAR);
    }

    const callCenterParent = {
        ...Routes.THJONUSTUVER.addSubPages(...callCenter),
    };

    const office = [];

    if (ability(user).can("read", "thjonustuver-billing")) {
        office.push(Routes.REIKNINGAR_THJONUSTUVER);
    }
    if (ability(user).can("read", "answer-companies-billing")) {
        office.push(Routes.REIKNINGAR_SVARA);
    }
    if (ability(user).can("read", "chat-billing")) {
        office.push(Routes.REIKNINGAR_NETSPJALL);
    }
    if (ability(user).can("read", "api-billing")) {
        office.push(Routes.REIKNINGAR_API);
    }
    if (ability(user).can("read", "sms-billing")) {
        office.push(Routes.REIKNINGAR_SMS);
    }
    if (ability(user).can("read", "svar-billing")) {
        office.push(Routes.SVAR);
    }

    const officeParent = {
        ...Routes.SKRIFSTOFA.addSubPages(...office),
    };

    const database = [];

    if (ability(user).can("manage", "address-requests")) {
        database.push(Routes.HEIMILISFANGABEIDNIR);
    }

    if (ability(user).can("manage", "company-requests")) {
        database.push(Routes.FYRIRTAEKJABEIDNIR);
    }

    if (ability(user).can("read", "statistics")) {
        database.push(Routes.TOLFRAEDI);
    }

    if (ability(user).can("manage", "postal-codes")) {
        database.push(Routes.POSTNUMER);
    }

    if (ability(user).can("read", "phone-company-updates")) {
        database.push(Routes.SIMFELOGIN);
    }

    if (ability(user).can("read", "new-companies")) {
        database.push(Routes.NY_FYRIRTAEKI);
    }

    if (ability(user).can("read", "subscriptions")) {
        database.push(Routes.PAKKAR);
    }

    if (ability(user).can("read", "listavinnsla")) {
        database.push(Routes.LISTAVINNSLA);
    }

    const databaseParent = {
        ...Routes.GAGNAGRUNNUR.addSubPages(...database),
    };

    const accessControl = [];
    if (ability(user).can("read", "employees")) {
        accessControl.push(Routes.STARFSFOLK);
    }
    if (ability(user).can("read", "sms-users")) {
        accessControl.push(Routes.SMS_NOTENDUR);
    }
    if (ability(user).can("read", "api-clients")) {
        accessControl.push(Routes.API_NOTENDUR);
    }
    if (ability(user).can("read", "roles")) {
        accessControl.push(Routes.HLUTVERK);
    }

    const accessControlParent = {
        ...Routes.ADGANGSSTYRING.addSubPages(...accessControl),
    };

    const managerCompanies = user?.additional_companies?.filter((c) => c.permissions === 2);

    menu.splice(
        1,
        0,
        ...[
            ability(user).can("read", "own-sms-users") ? Routes.SMS_GATT : null,
            callCenter.length > 0 && callCenterParent,
            ability(user).can("read", "thjodskra") ? Routes.THJODSKRA : null,
            ability(user).can("manage", "advertisements") ? Routes.AUGLYSINGAR : null,
            ability(user).can("manage", "call-campaigns") ? Routes.UTHRINGIVERKEFNI : null,
            Routes.SMS_GATT_SKRANING,
            ability(user).can("manage", "registration") ||
            ability(user).can("update", "own-registration")
                ? Routes.SKRANING
                : null,
            managerCompanies?.length > 0 ? Routes.STILLINGAR : null,
            ability(user).can("read", "answering-company-stats")
                ? Routes.ANSWERING_COMPANY_STATS
                : null,
            office.length > 0 && officeParent,
            database.length > 0 && databaseParent,
            accessControl.length > 0 && accessControlParent,
        ]
    );

    return menu;
}
