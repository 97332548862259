import { types } from "@redux/actions/authActions";
import { localStorageUtil } from "@utils/storage";
import toast from "react-hot-toast";

const initialState = {
    user: localStorageUtil.getItem("user"),
    access_token: localStorageUtil.getItem("access_token"),
    isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
    if (initialState) {
        state = initialState;
    }

    switch (action.type) {
        case types.set_tokens:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
            };
        case types.user_logged_in:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
            };
        case types.get_user:
            return {
                ...state,
                user: action.payload,
            };
        case types.user_logged_out:
            toast.success("Þú hefur verið skráð(ur) út", {
                position: "top-right",
                style: {
                    marginTop: 64,
                },
            });
            return {
                ...state,
                user: null,
                token: null,
                isAuthenticated: false,
            };
        case types.login_error:
            toast.error(action?.payload?.toString(), {
                position: "top-right",
                style: {
                    marginTop: 64,
                },
            });

            return {
                ...state,
                user: null,
                token: null,
                isAuthenticated: false,
            };
        default: {
            return state;
        }
    }
};

export default authReducer;
