import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { HeaderLogo } from "./logo";
import HamburgerIcon from "@components/icons/HamburgerIcon";
import useUser from "@hooks/use_user";
import { FlexContainer } from "@components/ui/flex_container";
import dynamic from "next/dynamic";
import { LOGIN_PATH } from "@utils/constants";
import NotificationBubble from "@components/NotificationBubble/NotificationBubble";
import { useStatus } from "@hooks/mitt";
const T1819SearchBar = dynamic(() => import("@components/T1819SearchBar"));

function PageHeader({ onMenuClick, showsSearchBar, height, searchQuery }) {
    const user = useUser();
    const router = useRouter();
    const { data: status } = useStatus();

    return (
        <header className="page-header" style={{ height }}>
            {!showsSearchBar && router.pathname == "/" && (
                <Link href="/um/thjonusta/simsvorun">
                    <a>
                        <div
                            className=" d-none d-md-flex align-items-center justify-content-center"
                            style={{
                                flexDirection: "column",
                                cursor: "pointer",
                                display: "flex",
                                verticalAlign: "center",
                                position: "absolute",
                                color: "#fff",
                                fontWeight: 600,
                                lineHeight: "1.7rem",
                                fontSize: "1.15rem",
                                left: 0,
                                right: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "400px",
                                height: 65,
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img style={{ height: 0, width: 0 }} />
                            <div style={{ color: "inherit" }}>Símsvörun fyrir þitt fyrirtæki</div>
                            <div style={{ color: "inherit" }}>Prófaðu frítt í viku</div>
                        </div>
                    </a>
                </Link>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        maxWidth: 1296,
                        marginLeft: 20,
                        marginRight: 20,
                        alignItems: "flex-start",
                    }}
                >
                    <HeaderLogo />

                    {showsSearchBar ? (
                        <FlexContainer style={{ flex: 1, justifyContent: "center" }}>
                            <div style={{ flex: 1, marginTop: 12, maxWidth: 675 }}>
                                <T1819SearchBar
                                    showGoogle
                                    small
                                    searchQuery={searchQuery}
                                    style={{ marginLeft: 5 }}
                                    searchSuggestions
                                />
                            </div>
                        </FlexContainer>
                    ) : (
                        <div style={{ flex: 2 }} />
                    )}

                    <div style={{ flexShrink: 1 }}>
                        <FlexContainer alignItems="center" style={{ height: 65 }}>
                            <FlexContainer className={showsSearchBar && "header-accessories"}>
                                <Link href={user ? "/mitt/notandi" : LOGIN_PATH}>
                                    <a style={{ position: "relative" }}>
                                        <div
                                            title="Skráðu þig!"
                                            className="d-flex flex-column align-items-center"
                                            style={{
                                                color: "white",
                                                fontSize: "13px",
                                                marginLeft: "20px",
                                                marginTop: 7,
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <img
                                                src="/img/admin-user.png"
                                                style={{
                                                    height: 23,
                                                    marginTop: 8,
                                                    marginBottom: 1,
                                                }}
                                                alt="Notandi"
                                            />

                                            {user?.full_name?.split(" ")[0]
                                                ? user?.full_name?.split(" ")[0]
                                                : "Mitt 1819"}
                                        </div>
                                        {user && (
                                            <NotificationBubble
                                                style={{ position: "absolute", top: 6, right: 0 }}
                                                text={Object.values(status || {}).reduce(
                                                    (a, b) => a + b,
                                                    0
                                                )}
                                                small
                                            />
                                        )}
                                    </a>
                                </Link>

                                <MenuButton src="/img/iceland.png" alt="Íslandskort" link="/kort" />
                            </FlexContainer>
                            <a rel="nofollow">
                                <HamburgerIcon
                                    width="40px"
                                    height="40px"
                                    color="white"
                                    style={{ marginLeft: 5 }}
                                    onClick={onMenuClick}
                                />
                            </a>
                        </FlexContainer>
                    </div>
                </div>
            </div>
        </header>
    );
}

function MenuButton({ onClick, title, ...props }) {
    return (
        <Link href={props.link ? props.link : "#"}>
            <a
                title={title}
                onClick={() => {
                    if (onClick) onClick();
                }}
            >
                <img
                    alt={props.alt}
                    style={{
                        height: 65,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                    src={props.src}
                />
            </a>
        </Link>
    );
}

export default PageHeader;
