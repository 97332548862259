import React from "react";
import classNames from "classnames";

function Modal({ children, className }) {
    return (
        <>
            <div
                className={classNames("modal show", className)}
                style={{
                    display: "block",
                }}
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">{children}</div>
                </div>
            </div>
            <ModalBackdrop />
        </>
    );
}

export function ModalBody({ children }) {
    return (
        <div
            className="modal-body"
            style={{
                marginRight: "15px",
                marginLeft: "15px",
            }}
        >
            {children}
        </div>
    );
}

export function ModalFooter({ children }) {
    return <div className="modal-footer">{children}</div>;
}

export function CloseButton({ onClick }) {
    return (
        <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onClick()}
        >
            <span aria-hidden="true">&times;</span>
        </button>
    );
}

export function ModalBackdrop() {
    return <div className="modal-backdrop fade show"></div>;
}

export default Modal;
