import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import CloseButton from "../close_button";
import { useRouter } from "next/router";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import IcelandFlag from "@components/icons/flags/IcelandFlag";
import UKFlag from "@components/icons/flags/UKFlag";
import { FlexContainer } from "@components/ui/flex_container";
import { THEME_COLOR } from "@utils/constants";
import useUser from "@hooks/use_user";
import MenuBuilder from "@config/sidebars/innra_routes/MenuBuilder";
import { SideBarElement } from "@components/side_bar";
import styles from "./SideMenu.module.scss";
import { AnimatePresence } from "framer-motion";
import dynamic from "next/dynamic";
const MotionDiv = dynamic(() => import("framer-motion").then((mod) => mod.motion.div));

function SideMenu({ show, onClose }) {
    const user = useUser();
    const t = GetTranslatedStrings();
    const router = useRouter();
    const [changes, setChanges] = useState(0);

    const [menu, setMenu] = React.useState([]);
    useEffect(() => {
        setMenu(MenuBuilder(user));
    }, [user]);

    useEffect(() => {
        console.log(`Route changed to: ${router.pathname}`);
        setChanges((prev) => prev + 1);
        onClose();
    }, [router.pathname]);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [show]);

    return (
        <>
            <AnimatePresence>
                <div
                    className={classNames(styles.pageBlocker, { [styles.block]: show })}
                    onClick={onClose}
                ></div>
            </AnimatePresence>
            <aside
                className={classNames(
                    styles.sideMenu,
                    { [styles.showMenu]: !show },
                    "torgid-background"
                )}
            >
                <CloseButton onClick={onClose} />
                <div
                    style={{
                        padding: "60px 8px 50px 8px",
                        overflow: "auto",
                    }}
                >
                    <div
                        className={styles.mobileMenuContainer}
                        style={{ padding: "10px 10px", marginBottom: 10 }}
                    >
                        <nav style={{ position: "sticky", top: 80, marginBottom: 20 }}>
                            {menu.map((menuItem) => {
                                return (
                                    <SideBarElement
                                        key={menuItem?.title + menuItem?.link}
                                        menuItem={menuItem}
                                    />
                                );
                            })}
                        </nav>
                    </div>
                    <div style={{ padding: "0px 30px" }}>
                        <FlexContainer justifyContent="around" className={classNames("mb-5")}>
                            <Link href={router.asPath} locale="is">
                                <a aria-label="Íslenska">
                                    <IcelandFlag
                                        style={{
                                            height: 50,
                                            width: 50,
                                            border:
                                                router.locale == "is"
                                                    ? "solid 3px " + THEME_COLOR
                                                    : "solid 1px #ccc",
                                            borderRadius: 14,
                                        }}
                                    />
                                </a>
                            </Link>
                            <Link href={router.asPath} locale="en">
                                <a aria-label="English">
                                    <UKFlag
                                        style={{
                                            height: 50,
                                            width: 50,
                                            border:
                                                router.locale == "en"
                                                    ? "solid 3px " + THEME_COLOR
                                                    : "solid 1px #ccc",
                                            borderRadius: 14,
                                        }}
                                    />
                                </a>
                            </Link>
                        </FlexContainer>
                        {user ? (
                            <div className={styles.desktopMenuContainer}>
                                <Link href="/mitt/notandi">
                                    <a>
                                        <FlexContainer
                                            alignItems="center"
                                            gap={10}
                                            style={{ margin: "20px 0px" }}
                                        >
                                            <img
                                                src="/img/sidebar/user.png"
                                                height={30}
                                                width={30}
                                            />
                                            <p style={{ margin: 0, fontWeight: 600 }}>
                                                {user.full_name}
                                            </p>
                                        </FlexContainer>
                                    </a>
                                </Link>

                                <Link href="/logout">
                                    <a>
                                        <FlexContainer
                                            alignItems="center"
                                            gap={10}
                                            style={{ margin: "20px 0px" }}
                                        >
                                            <img
                                                src="/img/sidebar/log-out.png"
                                                height={30}
                                                width={30}
                                            />
                                            <p style={{ margin: 0, fontWeight: 600 }}>{t.logout}</p>
                                        </FlexContainer>
                                    </a>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )}

                        <ul className={styles.menuItems}>
                            <MenuItem href="/" text={t.home} />
                            {user ? <></> : <MenuItem href="/innskraning" text={t.login} />}
                            <MenuItem href="/kort" text={t.map} />
                            <MenuItem href="/um/thjonusta/vorur" text={t.services_1819} />
                            <MenuItem href="/um/fyrirtaekid/um" text={t.about_1819} />
                        </ul>
                    </div>
                </div>
            </aside>
        </>
    );
}

function MenuItem({ href, text }) {
    return (
        <li>
            <Link href={href}>
                <a>{text}</a>
            </Link>
        </li>
    );
}

export default SideMenu;
