import DraggableModal from "@components/draggable_modal/draggable_modal";
import { FlexContainer } from "@components/ui/flex_container";
import React from "react";
import { Button } from "@components/ui/button";
import { connect, useDispatch } from "react-redux";
import { closeCommentModal, setCommentType } from "@redux/actions/phoneSystemActions";
import { fetcherNew1819 } from "@utils/fetcherNew1819";

import dynamic from "next/dynamic";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
const AnimatePresence = dynamic(() => import("framer-motion").then((mod) => mod.AnimatePresence));
const MotionDiv = dynamic(() => import("framer-motion").then((mod) => mod.motion.div));

const mapStateToProps = (state) => {
    return {
        showCommentModal: state.phoneSystem.comment_modal_open,
        recipientEmail: state.phoneSystem.email_modal.recipient,
        comment: state.phoneSystem.selected_call_log,
        commentType: state.phoneSystem.comment_type,
    };
};

function CommentModal({ onRequestClose, ...props }) {
    const dispatch = useDispatch();
    const [infoDescription, setInfoDescription] = React.useState("");
    const [isSending, setIsSending] = React.useState(false);
    const [isSent, setIsSent] = React.useState(false);

    const sendEmail = async () => {
        let body = {
            phone: props.comment.phone,
            comment: infoDescription,
            positive: props.commentType === "positive",
            queue_extension: props.comment.queueNumber,
        };
        try {
            setIsSending(true);
            await fetcherNew1819("mitt/thjonustuver/comments", "POST", body);
            setIsSending(false);
            setIsSent(true);
            setTimeout(() => {
                setInfoDescription("");
                setIsSent(false);
                dispatch(closeCommentModal());
            }, 2000);
        } catch (e) {
            console.log(e);
        } finally {
            setIsSending(false);
        }
    };
    return (
        <AnimatePresence>
            {props.showCommentModal && (
                <MotionDiv variants={dropIn} initial="hidden" animate="visible" exit="exit">
                    <DraggableModal
                        isOpen={true}
                        headerTitle="Skrá athugasemd"
                        headerSubtitle={`Símanúmer: ${props.comment.phone} → ${props.comment.queueName}`}
                        onRequestClose={onRequestClose}
                    >
                        {isSent ? (
                            <FlexContainer
                                column
                                justifyContent="center"
                                alignItems="center"
                                style={{ height: 200 }}
                            >
                                <img
                                    src="/img/circled-checkmark.svg"
                                    style={{ height: 100, width: 100 }}
                                />
                                <h5 className="mt-4">Athugasemd skráð</h5>
                            </FlexContainer>
                        ) : (
                            <>
                                <form
                                    id="company-email-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <FlexContainer gap={10} style={{ marginTop: 10 }}>
                                        <Button
                                            inverted={props.commentType !== "positive"}
                                            style={{ padding: "2px 12px", flex: 1 }}
                                            onClick={() => {
                                                dispatch(setCommentType("positive"));
                                            }}
                                            icon={faThumbsUp}
                                            color="#198754"
                                        />
                                        <Button
                                            inverted={props.commentType !== "negative"}
                                            style={{ padding: "2px 12px", flex: 1 }}
                                            onClick={() => {
                                                dispatch(setCommentType("negative"));
                                            }}
                                            icon={faThumbsDown}
                                            color="#cf0000"
                                        />
                                    </FlexContainer>
                                    <div style={{ display: "flex" }}>
                                        <label htmlFor="description">Athugasemd</label>
                                    </div>
                                    <textarea
                                        id="description"
                                        className="form-control"
                                        style={{ minHeight: 150 }}
                                        value={infoDescription}
                                        onChange={(e) => setInfoDescription(e.target.value)}
                                    />
                                </form>
                                <FlexContainer>
                                    <Button
                                        text="Skrá athugasemd"
                                        isLoading={isSending}
                                        onClick={sendEmail}
                                        style={{
                                            marginTop: 10,
                                            fontSize: 20,
                                            flexGrow: 1,
                                            fontWeight: 600,
                                            maxHeight: 40,
                                            height: 40,
                                        }}
                                    />
                                </FlexContainer>
                            </>
                        )}
                    </DraggableModal>
                </MotionDiv>
            )}
        </AnimatePresence>
    );
}

const dropIn = {
    hidden: {
        x: "-50px",
        opacity: 0,
    },
    visible: {
        x: "0px",
        opacity: 1,
        transition: {
            duration: 0.3,
            ease: "easeInOut",
        },
    },
    exit: {
        x: "-50px",
        opacity: 0,
        transition: {
            duration: 0.3,
            ease: "easeInOut",
        },
    },
};

export default connect((state) => mapStateToProps(state))(CommentModal);
