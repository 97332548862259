import React from "react";
import { Image } from "@components/ui";

export const HeaderLogo = () => (
    <a href="/" style={{ flexShrink: 0 }}>
        <Image
            className="logo-container"
            width="29px"
            src="/img/1819logo-white.svg"
            alt="1819 merki"
        />
    </a>
);
