import React from "react";

import SideBar from "@components/side_bar";
import { FlexContainer } from "@components/ui/flex_container";
import Page from "@components/layout/page";
import styles from "./InfoLayout.module.scss";

export default function InfoLayout({ title, menu, children }) {
    return (
        <Page showsSearchBar title={title}>
            <FlexContainer className={styles.container} gap="25px" style={{ maxWidth: 1100 }}>
                <SideBar menu={menu} />
                <div>{children}</div>
            </FlexContainer>
        </Page>
    );
}
