import React from "react";
import { FlexContainer } from "@components/ui/flex_container";
import Link from "next/link";
import HorizontalAd from "@components/HorizontalAd/HorizontalAd";
import dynamic from "next/dynamic";
const T1819SearchBar = dynamic(() => import("@components/T1819SearchBar"));

export default function FrontPage({ onSearchBarChange }) {
    const searchRef = React.useRef(null);
    searchRef.current?.focus?.();
    return (
        <FlexContainer column justifyContent="center" alignItems="center" style={{ flexGrow: 1 }}>
            <div style={{ paddingTop: "5vh" }}></div>
            <Link href="/">
                <a>
                    <img
                        src="/img/1819logo-blue.svg"
                        alt="1819 merki"
                        style={{
                            height: "15vh",
                            maxHeight: "160px",
                            filter: "drop-shadow(0px 2px 2px #00000022)",
                        }}
                    />
                </a>
            </Link>
            <div style={{ paddingTop: "8vh" }}></div>
            <T1819SearchBar
                showGoogle
                maxWidth={900}
                onChange={onSearchBarChange}
                ref={searchRef}
            />
            <div style={{ paddingTop: "8vh" }}></div>
            <HorizontalAd adPosition={1} />
            <div style={{ paddingTop: "21vh" }}></div>
        </FlexContainer>
    );
}
