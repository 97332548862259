import React from "react";
import classNames from "classnames";

const BorderedContainer = (props) => {
    const { children, className } = props;
    return (
        <div
            {...props}
            className={classNames(className, "bordered-container", "torgid-background-secondary")}
        >
            {children}
        </div>
    );
};
export default BorderedContainer;
