import React from "react";
import { Row } from "@components/ui";
import SideBar from "@components/side_bar";
import authenticated_route from "@navigation/authenticated_route";
import innra_skrar from "@config/sidebars/pages/innra_skrar";

function InnraSkrarLayout({ children }) {
    return (
        <Row>
            <div className="col-md-9 col-sm-12 col-xl-10">{children}</div>
            <div className="col-md-3 col-xl-2">
                <SideBar menu={innra_skrar} />
            </div>
        </Row>
    );
}
export default authenticated_route(InnraSkrarLayout);
