import { useRouter } from "next/router";

export default function getTranslatedString({ is, en, pl }) {
    const locale = useRouter()?.locale;
    switch (locale) {
        case "is":
            return is;
        case "en":
            if (!en) {
                return is;
            }
            return en;
        case "pl":
            if (!pl) {
                if (!en) {
                    return is;
                }
                return en;
            }
            return pl;
        default:
            return is;
    }
}
