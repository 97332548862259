import * as React from "react";

const CloseIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="white"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-x"
        {...props}
    >
        <path d="M18 6 6 18M6 6l12 12" />
    </svg>
);

export default CloseIcon;
