import React from "react";
import classNames from "classnames";

export function Col({ col, sm, md, lg, xl, children, className, ...props }) {
    const classes = classNames(className, {
        [`col-sm-${sm}`]: sm,
        [`col-md-${md}`]: md,
        [`col-lg-${lg}`]: lg,
        [`col-xl-${xl}`]: xl,
        [`col-${col}`]: col,
    });

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    );
}

export function Container({ children, fluid = false, className, ...props }) {
    const classes = classNames(className, {
        "container-fluid": fluid,
        container: !fluid,
    });

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    );
}

export function Row(props) {
    let { children, className } = props;
    return (
        <div {...props} className={classNames("row", className)}>
            {children}
        </div>
    );
}
