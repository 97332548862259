import { fetcherNew1819 } from "@utils/fetcherNew1819";
import { fetcherPublic1819 } from "@utils/fetcherPublic1819";
import useSWR from "swr";

export const useRegistrationDetails = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`1819/details?id=${id}`, fetcherNew1819);
};

export const useSearchSuggestions = (query) => {
    if (!query) return useSWR(null, fetcherPublic1819);
    return useSWR(`1819/suggest?query=${query}`, fetcherPublic1819);
};
