import { FlexContainer } from "@components/ui/flex_container";
import React from "react";

export default function NotificationBubble({ text, small, ...props }) {
    if (!text) {
        return null;
    }
    let style = {
        background: "#cf0000",
        position: "absolute",
        top: 0,
        right: 0,
        borderRadius: "500px",
        lineHeight: "1rem",
        height: "1.3rem",
        padding: "0 0.5rem",
        minWidth: "1.8rem",
        ...props.style,
    };
    if (small) {
        style = {
            ...style,
            fontSize: "0.8rem",
            height: "1rem",
            padding: "0 0.3rem",
            minWidth: "1.3rem",
        };
    }
    return (
        <FlexContainer justifyContent={"center"} alignItems={"center"} {...props} style={style}>
            <div style={{ color: "white", fontWeight: 600 }}>{text}</div>
        </FlexContainer>
    );
}
