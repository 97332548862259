export default {
    welcome: "Welcome to 1819",
    enable_offer: "Enable Offer",
    website: "Website",
    offer_enabled_for: "Offer enabled for",
    sec: "sec.",
    close: "Close",
    about: "1819",
    most_popular: "Most Popular Offers",
    newest_first: "Newest Offers First",
    oldest_first: "Oldest Offers First",
    sort: "Sort by",
    active_now: "Active Now",
    load_more: "Load More Offers...",
    use_this_code: "Use this code to activate the offer",
    all_offers: "All Offers",
    top_offers: "Top Offers",
    home: "Home",
    show_promocode: "Show Promo Code",

    ad: "ADVERTISEMENT",

    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",

    monday_medium: "Mon",
    tuesday_medium: "Tue",
    wednesday_medium: "Wed",
    thursday_medium: "Thu",
    friday_medium: "Fri",
    saturday_medium: "Sat",
    sunday_medium: "Sun",

    monday_short: "M",
    tuesday_short: "T",
    wednesday_short: "W",
    thursday_short: "T",
    friday_short: "F",
    saturday_short: "S",
    sunday_short: "S",

    offer_not_available_today: "Offer not available today",
    offer_not_available: "Offer not available",
    offer_was_available: "Offer was available",
    offer_today: "today",
    offer_is_available: "Offer is available",
    offer_is_available_all_day: "Offer is available all day today",
    offer_will_be_available: "Offer will be available",
    special_offer: "Special Offer",
    two_for_one: "2 for 1",
    introduction: "Introduction",

    companies: "Companies",
    individuals: "Individuals",

    load_more_companies: "Load More Companies",
    load_more_individuals: "Load More Individuals",

    login: "Login",

    terms_conditions: "User Terms and Conditions",
    privacy_policy: "Privacy Policy",
    searchPlaceholder: "What are you looking for?",
    searchButton: "Search",

    loginContainerTitle: "Intra-web login for 1819.is",
    loginUsernameTitle: "Email",
    loginPasswordTitle: "Password",
    loginButtonTitle: "Log In",

    //==========================================================
    map: "Map",
    services_1819: "1819 Services",
    about_1819: "About 1819",
    open: "Open",
    closed: "Closed",

    front_page: "Front Page",
    search_results: "Search Results",
    search_results_for: "Search Results for",

    nothing_found_with: "Nothing found with the search term",
    nothing_found_using: "using",

    no_results_for: "No results were found for the search term",
    error_when_search: "An error occurred while searching",
    not_finding_what_you_are_looking_for: "Not finding what you are looking for?",
    we_can_help: "We can help you at 1819",
    call_1819: "Call 1819",
    have_a_suggestion: "Have a suggestion?",
    cannot_connect: "Can't connect to the 1819 server. Is your device connected to the internet?",
    think_this_is_error: "Think this is an error?",
    here_you_can_send_us: "Here you can send us a suggestion",
    send_us_a_message: "Send us a message",
    you_know_where_to_look: "...you know where to look.",

    //==========================================================
    // INFO DETAILS
    info_details_opening_hours: "Opening Hours",
    info_details_phone_number_singular: "Phone Number",
    info_details_phone_number_plural: "Phone Numbers",
    info_details_website_singular: "Website",
    info_details_website_plural: "Websites",
    info_details_email_singular: "Email",
    info_details_email_plural: "Emails",
    info_details_kennitala: "Kennitala",
    info_details_fyrirtaekjaskra: "Company Registry",
    info_details_keldan: "Keldan",
    info_details_social_media: "Social Media",
    info_details_map: "Map",
    info_details_tags: "Tags",
    info_details_brands: "Brands",
    info_details_services: "Services",
    info_details_other_listings: "Other Listings",
    info_details_employees: "Employees",
    info_details_navigate: "Navigate",
    info_details_more_info: "More Information",

    map_search_on_map: "Search on the Map",

    //==========================================================
    // OPENING HOURS
    opening_hours_open_until: "Open until",
    opening_hours_open_until_midnight: "Open until midnight",
    opening_hours_opens_at: "Opens at",
    opening_hours_closed: "Closed",
    opening_hours_always_open: "Always open",

    //==========================================================
    // FOOTER

    footer_opening_hours: "Opening Hours",
    footer_thjonustuver: "The 1819 number",
    footer_skrifstofa: "1819 Office",
    footer_weekdays: "Weekdays",
    footer_weekends: "Weekends",

    //==========================================================
    // MITT
    authenticating: "Authenticating...",
    shortcuts: "Shortcuts",
    my_account: "My Account",
    my_companies: "My Companies",
    my_data_at_1819: "My data at 1819",
    full_name: "Full Name",
    kennitala: "Kennitala",
    company_kennitala: "Company Kennitala",
    registration_date: "Registration Date",
    extension_3cx: "3CX Extension",
    roles: "Roles",
    sms_gateway: "SMS Gateway",
    service_phone: "Service Phone",
    thjodskra: "National Registry",
    scripts: "Scripts",
    my_registrations: "My Registrations",
    answering_stats: "Answering Stats",
    my_registration: "My Registration",
    register_company: "Register Company into 1819.is",
    my_data_description:
        "Here you can download the data we have on record about you. You can also delete yourself completely out of our system.",
    download_my_data: "Download my Data",
    delete_user: "Forget Me",

    register_company_modal_title: "Register a New Company into 1819.is",
    register_company_modal_info:
        "Please enter information about the company. These details will be visible on 1819.is when we have reviewed the request. After we have approved the request, you can add more information about the company.",

    register_company_modal_info_step: "Information",
    register_company_modal_access_step: "Access",
    register_company_modal_communication_step: "Communication",
    register_company_modal_done_step: "Done",

    company_address: "Company Address",

    // Edit Registration
    registration: "Registration",
    information: "Information",
    company_info: "Company Info",
    branches: "Branches",
    create: "Create",
    save: "Save",
    get_info_from_thjodskra: "Fetch Information from the National Registry",
    fetch: "Fetch",
    edit: "Edit",
    basic_info: "Basic Information",
    job_title: "Job Title",
    activity: "Activity",
    show_keldan: "Show Keldan",
    show_company_registry: "Show Company Registry",
    address: "Address",
    street_name: "Street Name",
    house_number: "House Number",
    postal_code: "Postal Code",
    select_postal_code: "Select Postal Code",
    show_address: "Show Address",
    allow_updates: "Allow Automatic Updates from Þjóðskrá",
    get_location: "Get Location",
    phone_numbers: "Phone Numbers",
    banned: "Banned",
    visible: "Visible",
    add_phone_number: "Add Phone Number",
    add_email: "Add Email",
    add_website: "Add Website",
    add_social_media: "Add Social Media",
    no_phone: "No Phone Number",
    no_phone_details: "Click to add your phone number.",
    no_email: "No Email",
    no_email_details: "Click to add your email.",
    no_website: "No Website",
    no_website_details: "Click to add your website.",
    no_social_media: "No Social Media Links",
    no_social_media_details: "Click to add a social media profile.",
    actions: "Actions",
    preview: "Preview",
    preview_description_save: "This is how your registration looks like on 1819.is!",
    preview_description_create: "This is how your registration will look like on 1819.is!",
    logo: "Logo",
    header: "Header Photo",
    upload: "Upload",
    slugs: "Slugs",
    background_color: "Background Color",
    keywords_is: "Keywords IS",
    keywords_en: "Keywords EN",
    services_is: "Services IS",
    services_en: "Services EN",
    brands: "Brands",
    main_hours: "Main",
    other_hours: "Additional",
    new_slug: "New Slug",
    no_slugs: "No Slugs",
    new_opening_hours: "New Opening Hour",
    special_hours: "Special Opening Hours",
    no_special_hours: "No special opening hours",
    click_enter_to_add: "Press Enter to add",
    opens: "Opens",
    closes: "Closes",

    sub_registrations: "Branches",
    staff: "Staff",

    no_branches: "No Branches",
    no_branches_subtitle: "You can add a new branch by clicking the button above.",
    new_branch: "New Branch",

    no_staff: "No Staff",
    no_staff_subtitle: "You can add a new staff member by clicking the button above.",
    new_staff: "New Staff",

    logout: "Log Out",

    settings: "Settings",
    settings_for: "Settings for",
    users_for: "Users for",
};
