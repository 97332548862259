import { API_1819_ENDPOINT } from "./constants";

export async function fetcher1819(path, method = "GET") {
    const api_endpoint = API_1819_ENDPOINT;

    const headers = {
        accept: "application/json",
        "content-type": "application/json",
    };

    let options = {
        method: method,
        headers: headers,
    };
    const result = await fetch(api_endpoint + path, options);

    if (result.ok) {
        return await result.json();
    } else {
        console.dir(result);
        console.dir(result.headers);
        console.dir(result.json());
        throw new Error("Something went wrong");
    }
}
