import React from "react";
import company_page from "@config/sidebars/pages/company_page";
import InfoLayout from "@components/InfoLayout/InfoLayout";
export default function CompanyLayout({ children }) {
    return (
        <InfoLayout title="Fyrirtækið" menu={company_page}>
            {children}
        </InfoLayout>
    );
}
