import React from "react";
import CookieConsent from "react-cookie-consent";
import Link from "next/link";

export default function TorgidCookieConsent() {
    return (
        <CookieConsent
            buttonText="Ég skil"
            disableStyles
            containerClasses="container cookie-container"
            buttonClasses="cookie-button"
            contentClasses="cookie-content"
        >
            Þessi vefsíða notar vafrakökur (e. Cookies) til að auka vafraupplifun þína
            <Link href="/um/fyrirtaekid/notkunarskilmalar/">
                <a className="cookie-link">Sjá skilmála hér.</a>
            </Link>
        </CookieConsent>
    );
}
