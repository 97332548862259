import React from "react";
import Page from "./layout/page";
import { Title } from "./ui";

export default function ErrorPage() {
    return (
        <Page showsSearchBar title="Villa kom upp.">
            <Title size={4}>Forritarar okkar hafa verið látnir vita.</Title>
            <Title size={5}>
                Vinsamlegast endurhladdu síðunni með því að ýta á Ctrl-R eða Cmd-R.
            </Title>
            <Title size={5}>Afsakaðu óþægindin sem þetta kann að hafa valdið.</Title>
        </Page>
    );
}
