import React from "react";
import dynamic from "next/dynamic";
const AnimatePresence = dynamic(() => import("framer-motion").then((mod) => mod.AnimatePresence));
const AnimateSharedLayout = dynamic(() =>
    import("framer-motion").then((mod) => mod.AnimateSharedLayout)
);
const MotionDiv = dynamic(() => import("framer-motion").then((mod) => mod.motion.div));

import { useRouter } from "next/router";

export default function MotionTransition({ children }) {
    const router = useRouter();
    return (
        <AnimateSharedLayout>
            <AnimatePresence>
                <MotionDiv
                    transition={{ type: "switch", duration: 0.3 }}
                    initial={{ x: 15, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    key={router.route}
                >
                    {children}
                </MotionDiv>
            </AnimatePresence>
        </AnimateSharedLayout>
    );
}
