import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import classNames from "classnames";
import { FlexContainer } from "@components/ui/flex_container";
import CrossIcon from "./icons/CrossIcon";
import GoogleIcon from "./icons/GoogleIcon";
import Link from "next/link";
import useUser from "@hooks/use_user";
import { mutate } from "swr";
import { useSearchSuggestions } from "@hooks/1819";
import IndustryIcon from "./icons/IndustryIcon";
import PersonIcon from "./icons/PersonIcon";
import SearchIcon from "./icons/SearchIcon";
import ability from "@utils/ability";
import { useClickOutside } from "@react-hooks-hub/use-click-outside";

function mergeRefs(...inputRefs) {
    return (ref) => {
        inputRefs.forEach((inputRef) => {
            if (!inputRef) {
                return;
            }

            if (typeof inputRef === "function") {
                inputRef(ref);
            } else {
                // eslint-disable-next-line no-param-reassign
                inputRef.current = ref;
            }
        });
    };
}

const getRegistrationLink = (suggestion, query) => {
    let openLink;

    if (suggestion == null) {
        return (openLink = new URL("/?q=" + suggestion?.result, window.location.origin));
    }

    if (suggestion.contact_id == null) {
        return (openLink = new URL("/?q=" + suggestion?.result, window.location.origin));
    }
    openLink = new URL(`/info/${suggestion.contact_id}`, window.location.origin);
    if (suggestion.belongs_to != null) {
        openLink = new URL(`/info/${suggestion.belongs_to}`, window.location.origin);
        openLink.searchParams.append("id", suggestion.contact_id);
    }
    if (query) openLink.searchParams.append("q", query);
    return openLink;
};

const T1819SearchBar = React.forwardRef((props, ref) => {
    const {
        value,
        small,
        style,
        onSearch,
        maxWidth,
        placeholder,
        showGoogle,
        className,
        searchBarButtonStyle,
        onChange,
        searchSuggestionsEnabled = false,
        searchQuery: searchQueryProp,
        enableSearchResults = true,
    } = props;

    const user = useUser();
    const t = GetTranslatedStrings();
    const router = useRouter();
    const searchBar = useRef(null);
    const containerRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState((value ? value : router.query.q) || "");
    const { data, error } = useSearchSuggestions(enableSearchResults ? searchQuery : null);

    const [selectedIndex, setSelectedIndex] = useState(null);

    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [searchSuggestionsVisible, setSearchSuggestionsVisible] = useState(false);

    useEffect(() => {
        if (data) {
            setSearchSuggestions(data);
        }
    }, [data]);

    useEffect(() => {
        if (router.query.q) {
            setSearchQuery(router.query.q);
        }
    }, [router.query.q]);

    useEffect(() => {
        if (searchQuery == "") {
            setSearchSuggestionsVisible(false);
        }
    }, [searchQuery]);

    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            setSearchSuggestionsVisible(false);
            if (searchSuggestionsVisible && selectedIndex !== null) {
                let suggestion = searchSuggestions[selectedIndex];
                router.push(getRegistrationLink(suggestion, router.query.q));
            } else {
                _handleSearch();
            }
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            if (selectedIndex === null) {
                setSelectedIndex(0);
                return;
            }
            setSelectedIndex((selectedIndex + 1) % searchSuggestions.length);
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            if (selectedIndex === null) {
                setSelectedIndex(searchSuggestions.length - 1);
                return;
            }
            setSelectedIndex(
                (selectedIndex - 1 + searchSuggestions.length) % searchSuggestions.length
            );
        } else {
            setSearchSuggestionsVisible(true);
            if (searchSuggestionsEnabled) {
                console.log(searchSuggestions);
            }
        }
    };

    const _handleSearch = () => {
        if (onSearch) {
            onSearch(searchQuery);
            return;
        }
        if (!searchQuery || searchQuery?.length == 0) {
            return;
        }
        mutate(`1819/search?query=${searchQuery}`);
        router.push(`/?q=${searchQuery}`);
    };

    useEffect(() => {
        mutate(`1819/search?query=${searchQuery}`);
    }, [router]);

    const _handleClear = () => {
        setSearchQuery("");
        if (searchBar) {
            searchBar.current.focus();
        }
    };
    useEffect(() => {
        if (searchQueryProp) {
            setSearchQuery(searchQueryProp);
            searchBar.current.focus();
        }
    }, [searchQueryProp]);

    useClickOutside([containerRef], (isOutside) => {
        setSearchSuggestionsVisible(isOutside);
    });

    return (
        <>
            <FlexContainer
                ref={containerRef}
                className={classNames("search-bar-container", className)}
                style={{
                    maxWidth: maxWidth && !small ? maxWidth : null,
                }}
            >
                <input
                    ref={searchBar}
                    autoFocus={!small}
                    type="text"
                    placeholder={placeholder || t.searchPlaceholder}
                    value={searchQuery}
                    style={{
                        ...style,
                    }}
                    onKeyDown={_handleKeyDown}
                    onChange={(event) => {
                        setSearchQuery(event.target.value);
                        onChange && onChange(event.target.value);
                    }}
                    className={classNames(
                        "torgid-background-secondary torgid-border search-bar-input",
                        { small: small }
                    )}
                />

                <FlexContainer column className="search-suggestions-container">
                    {searchSuggestionsVisible &&
                        searchSuggestions?.map((suggestion, index) => {
                            let icon = null;
                            if (suggestion.logo_link) {
                                icon = (
                                    <img
                                        src={suggestion.logo_link}
                                        style={{
                                            maxHeight: 30,
                                            maxWidth: 32,
                                            aspectRatio: 1,
                                            objectFit: "contain",
                                        }}
                                    />
                                );
                            } else if (suggestion.is_type == 2) {
                                // Company
                                icon = (
                                    <IndustryIcon
                                        style={{
                                            color: "gray",
                                            maxHeight: 30,
                                            maxWidth: 32,
                                            aspectRatio: 1,
                                            objectFit: "contain",
                                        }}
                                    />
                                );
                            } else if (suggestion.is_type == 1) {
                                // PERSON
                                icon = (
                                    <PersonIcon
                                        src={suggestion.logo_link}
                                        style={{
                                            maxHeight: 30,
                                            maxWidth: 32,
                                            aspectRatio: 1,
                                            objectFit: "contain",
                                            color: "gray",
                                        }}
                                    />
                                );
                            } else {
                                // Keyword
                                icon = (
                                    <SearchIcon
                                        src={suggestion.logo_link}
                                        style={{
                                            maxHeight: 23,
                                            maxWidth: 32,
                                            aspectRatio: 1,
                                            objectFit: "contain",
                                            color: "gray",
                                        }}
                                    />
                                );
                            }

                            return (
                                <Link href={getRegistrationLink(suggestion, router.query.q)}>
                                    <FlexContainer
                                        onClick={() => {
                                            setSearchSuggestionsVisible(false);
                                        }}
                                        key={index}
                                        className={classNames("search-suggestion", {
                                            selected: selectedIndex === index,
                                        })}
                                    >
                                        <div
                                            style={{
                                                aspectRatio: 1,
                                                height: 30,
                                                width: 32,
                                                marginLeft: 10,
                                                marginRight: 10,
                                            }}
                                        >
                                            {icon}
                                        </div>
                                        {suggestion.result}
                                    </FlexContainer>
                                </Link>
                            );
                        })}
                </FlexContainer>

                <FlexContainer
                    style={{
                        position: "absolute",
                        flex: 1,
                        flexDirection: "row",
                        right: "10px",
                        padding: 0,
                        margin: 0,
                    }}
                    justifyContent="center"
                    alignItems="center"
                >
                    {user && user?.user_type != "normal" && showGoogle && (
                        <Link
                            href={
                                searchQuery.length == 0
                                    ? "#"
                                    : `https://www.google.com/search?q=${searchQuery}`
                            }
                        >
                            <a
                                target={searchQuery.length == 0 ? "" : "_blank"}
                                style={{ marginRight: small ? 0 : 5 }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#fbbc05",
                                        borderRadius: 100,
                                        flexGrow: 1,
                                    }}
                                >
                                    <GoogleIcon
                                        height={small ? 25 : 35}
                                        width={small ? 40 : 60}
                                        style={{
                                            marginBottom: "1px",
                                            justifySelf: "center",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            </a>
                        </Link>
                    )}

                    {small && searchQuery?.length != 0 && (
                        <>
                            <CrossIcon
                                height="15px"
                                style={{
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    justifySelf: "center",
                                    cursor: "pointer",
                                }}
                                onClick={_handleClear}
                            />
                        </>
                    )}

                    <button
                        type="submit"
                        className={classNames("search-bar-button", {
                            small: small,
                        })}
                        style={{ ...searchBarButtonStyle }}
                        onClick={_handleSearch}
                    >
                        {t.searchButton}
                    </button>
                </FlexContainer>
            </FlexContainer>
            {!small && (
                <button
                    className={classNames("search-bar-button-below", {
                        small: small,
                    })}
                    type="submit"
                    onClick={_handleSearch}
                >
                    {t.searchButton}
                </button>
            )}
        </>
    );
});

export default T1819SearchBar;
