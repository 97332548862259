export async function fetcherPublic1819(path, method = "GET", body, headers = {}) {
    const api_endpoint = process.env.API_NEW_1819_URL;
    const headersToSend = {
        accept: "application/json",
        "content-type": "application/json",
        "app-key": process.env.API_NEW_1819_APP_KEY,
        ...headers,
    };

    let options = {
        method: method,
        headers: headersToSend,
        body: JSON.stringify(body),
    };
    const result = await fetch(api_endpoint + path, options);
    if (result.ok) {
        const resultJSON = await result.json();
        return resultJSON;
    } else {
        let errorString = JSON.stringify(await result.json()) || "Request failed";
        throw { statusCode: result.status, message: errorString };
    }
}
