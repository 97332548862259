import Cookies from "js-cookie";

export const localStorageUtil = {
    getItem: (key) => {
        if (typeof window === "undefined") {
            return null;
        }
        const value = window.localStorage.getItem(key);
        if (value == "undefined") {
            return null;
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    },
    setItem: (key, value) => {
        window.localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem: (key) => {
        window.localStorage.removeItem(key);
    },
    clear: () => {
        window.localStorage.clear;
    },
};

export const cookieUtil = {
    setCookie: (key, val) => {
        Cookies.set(key, val, {
            expires: 2,
        });
    },
    getCookie: (key) => {
        const cookie = Cookies.get(key);
        return cookie;
    },
};
