import React from "react";
import services_page from "@config/sidebars/pages/services_page";
import InfoLayout from "@components/InfoLayout/InfoLayout";
export default function ServicesLayout({ children }) {
    return (
        <InfoLayout title="Þjónustur" menu={services_page}>
            {children}
        </InfoLayout>
    );
}
