import { defineAbility } from "@casl/ability";

export default (user) =>
    defineAbility((can) => {
        if (!user) {
            return;
        }
        const permissions = user?.permissions?.map?.((p) => ({
            action: p?.action,
            type: p?.type,
        }));
        permissions?.forEach((permission) => {
            can(permission.action, permission.type);
        });
    });
