import React from "react";
import classNames from "classnames";

export const Table = ({ children, className }) => {
    return (
        <div className="table-responsive">
            <table className={classNames("table", className)}>
                <tbody>{children}</tbody>
            </table>
        </div>
    );
};
