import React from "react";
import classNames from "classnames";

function Image({ src, width, height, className, color, style, alt }) {
    return (
        <img
            src={src}
            width={width}
            height={height}
            alt={alt}
            style={{ fill: color, ...style }}
            className={classNames("img", "img-fluid", className)}
        />
    );
}

export default Image;
