import { localStorageUtil } from "./storage";
import { store } from "@redux/store";
import { loginExpired } from "@redux/actions/authActions";
import Semaphore from "./semaphore";

const throttler = new Semaphore(1);

export async function fetcherNew1819(
    path,
    method = "GET",
    body,
    sendMultiPart = false,
    signal,
    isDownload = false
) {
    const api_endpoint = "/api/v1/";
    const accessToken = localStorageUtil.getItem("access_token");
    const headers = {
        accept: "application/json",
        "app-key": process.env.API_NEW_1819_APP_KEY,
    };

    if (!sendMultiPart) {
        headers["Content-Type"] = "application/json";
    }

    if (accessToken) {
        headers.authorization = `Bearer ${accessToken}`;
    }

    if (body && !sendMultiPart) {
        body = JSON.stringify(body);
    }

    let options = {
        method: method,
        headers: headers,
        body: body,
        signal: signal,
    };

    const result = await throttler.callFunction(fetch, api_endpoint + path, options, isDownload);
    if (result.ok) {
        try {
            if (isDownload) {
                return await result.blob();
            }
            const resultJSON = await result.json();
            return resultJSON;
        } catch (e) {
            return null;
        }
    } else if (result.status == 498) {
        await fetchNewAccessToken();
        if (body && !sendMultiPart) {
            body = JSON.parse(body);
        }
        return await fetcherNew1819(path, method, body);
    } else if (result.status == 401) {
        localStorageUtil.removeItem("access_token");
        localStorageUtil.removeItem("user");
        store.dispatch(loginExpired());
    } else if (result.status == 403) {
        return await fetchNewAccessToken();
        // if (body && !sendMultiPart) {
        //     body = JSON.parse(body);
        // }
        // return await fetcherNew1819(path, method, body);
    } else {
        try {
            let errorString = JSON.stringify(await result.json());
            throw { statusCode: result.status, message: errorString };
        } catch (e) {
            let errorString = e.message || "Náði ekki sambandi við vefþjón";
            throw { statusCode: result.status, message: errorString };
        }
    }
}

async function fetchNewAccessToken() {
    const accessToken = localStorageUtil.getItem("access_token");

    const headers = {
        accept: "application/json",
        "content-type": "application/json",
        "app-key": process.env.API_NEW_1819_APP_KEY,
    };

    if (accessToken) {
        headers.authorization = `Bearer ${accessToken}`;
    }

    let options = {
        method: "POST",
        headers: headers,
    };

    try {
        let tokens = await throttler.callFunction(fetch, "/api/v1/token", options);
        if (tokens.status == 401) {
            localStorageUtil.removeItem("access_token");
            localStorageUtil.removeItem("user");
            store.dispatch(loginExpired());
        }
        tokens = await tokens.json();
        localStorageUtil.setItem("access_token", tokens.access_token);
    } catch (e) {
        console.log(e);
    }
}
