import React, { useEffect, useState } from "react";

import { fetcher1819 } from "@utils/fetcher1819";
import { useRouter } from "next/router";
import { FlexContainer } from "@components/ui/flex_container";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import styles from "./HorizontalAd.module.scss";

export default function HorizontalAd({ adPosition, className, style }) {
    if (!adPosition) {
        console.error("No ad position provided");
        return null;
    }
    const t = GetTranslatedStrings();
    const router = useRouter();
    const [ad, setAd] = useState(null);

    const fetchAds = async () => {
        try {
            let fetchedAd = await fetcher1819(`auglysing/${adPosition}/${router.query.q}`);
            if (fetchedAd.length > 0) {
                setAd(fetchedAd[0]);
            }
        } catch (e) {
            console.error("Error fetching ad", e);
        }
    };

    useEffect(() => {
        fetchAds();
    }, [router.query.q]);

    let adLink = null;
    if (ad !== null && ad !== undefined) {
        adLink = `https://old.1819.is/linkto/${ad?.ad_id}/3?url=${ad?.linkto}`;
    }

    return (
        <div style={{ display: "flex", ...style }}>
            <FlexContainer
                column
                className={className}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                }}
            >
                <a href={adLink} target="_blank" rel="noopener noreferrer">
                    <picture style={{ aspectRatio: 1018 / 128 }}>
                        <img src={ad?.url} className={styles.container} alt={t.ad} />
                    </picture>
                </a>
                {ad?.url && (
                    <div
                        style={{
                            color: "gray",
                            fontSize: "0.75em",
                            marginTop: "0.5em",
                        }}
                    >
                        {t.ad}
                    </div>
                )}
            </FlexContainer>
        </div>
    );
}
